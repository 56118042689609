import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import styled from "styled-components";
import useAxios from "axios-hooks";

import client from "apollo/index";
import { ChannelThumb } from "components/ChannelThumb";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
`;

const FixedLogo = styled.img.attrs({
  src: "ik-chess-camp-2021.png",
})`
  align-self: center;
  width: 33%;
  max-width: 400px;
  margin-top: 6em;

  @media only screen and (max-width: 900px) {
    width: 66%;
  }
`;

const Content = styled.div`
  width: 100vw;
  position: relative;
  padding: 3em 6em;

  @media only screen and (max-width: 900px) {
    padding: 1em;
  }
`;

const OneThird = styled.div`
  width: 100%;
  padding: 0 1em 0 0;

  @media only screen and (max-width: 900px) {
    width: 100%;
    flex: 1;
  }
`;

const Description = styled.div`
  font-size: 1.2em;
  line-height: 1.25em;
  display: flex;
  padding: 3em 0 0;
  flex-direction: column;
  width: 50%;

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Products = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  border-top: 1px solid #ccc;
  padding-top: 3em;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    padding-top: 0;
  }
`;

const ProductP = styled.p`
  height: 6em;
  line-height: 1.4;

  @media only screen and (max-width: 900px) {
    padding: 3em 0;
    height: auto;
  }
`;

const Product = styled.a<{ soldOut?: boolean }>`
  display: block;
  position: relative;
  img {
    max-width: 100%;
  }

  div {
    display: none;
  }

  ${(props) => {
    if (props.soldOut) {
      return `
        &:hover div {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0,0,0,0.1);
          color: white;
          font-size: 22px;
          font-weight: bold;
        }
      `;
    }
  }}
`;

const PreorderProduct = styled(Product as any)`
  img:last-child {
    display: none;
  }

  &:hover img:first-child {
    display: none;
  }

  &:hover img:last-child {
    display: block;
  }
`;

const Main: React.FC = () => {
  const [{ data, loading, error }] = useAxios("/api/vintage-shirts");

  const products =
    !loading &&
    !error &&
    data.products &&
    (data.products.edges as Array<any>).length > 0 &&
    (data.products.edges as Array<any>);

  const [
    { data: shirtData, loading: shirtLoading, error: shirtError },
  ] = useAxios("/api/preorder-shirt");

  const shirtProducts =
    !shirtLoading &&
    !shirtError &&
    shirtData.products &&
    (shirtData.products.edges as Array<any>).length > 0 &&
    (shirtData.products.edges as Array<any>);

  return (
    <ApolloProvider client={client}>
      <Container>
        <FixedLogo />
        <Content>
          <Description>
              <p>
                For Juneteeth 2021,{" "}
                <a href="https://intrnlknwldg.com">Internal Knowledge</a> and{" "}
                <a href="https://www.are.na">Are.na</a> present our second Chess Camp – a
                partnership exploring <strong>strategy</strong>,{" "}
                <strong>empathy</strong>, <strong>psychological freedom</strong>{" "}
                and <strong>Black history</strong>.
              </p>
              <p>
                This year, we will celebrate Juneteenth in person at Prospect Park in Brooklyn at the Concert Grove Pavilion with tables, chairs, chess boards, our friends and our minds. Please join us.
              </p>
              <p>
                12pm - until<br />
                Prospect Park at the Concert Grove
              </p>
              <p>
                
              </p>
          </Description>
          <Products>
            <OneThird>
              <ProductP>
                This year we will be selling a Chess Camp commemorative t-shirt. A portion of the proceeds will be donated to the{" "}
                <a href="https://www.beam.community">BEAM</a>, an
                organization dedicated to the emotional/mental health and
                healing of Black communities.
              </ProductP>
              {shirtProducts &&
                shirtProducts.map((product: any) => {
                  const imgOne =
                    product.node.images.edges[0].node.transformedSrc;
                  const imgTwo =
                    product.node.images.edges[1].node.transformedSrc;
                  return (
                    <PreorderProduct href={product.node.onlineStoreUrl}>
                      <img src={imgOne} />
                      <img src={imgTwo} />
                    </PreorderProduct>
                  );
                })}
            </OneThird>
            <OneThird>
              <ProductP>
                The Chess Camp{" "}
                <a href="https://www.are.na/chess-camp-by-internal-knowledge-are-na">
                  archives
                </a>{" "}
                are also available here for free, in four separate volumes.
              </ProductP>
              <ChannelThumb id="chess-camp-vol-1" direction="DESC"/>
              <ChannelThumb id="chess-camp-vol-2" />
              <ChannelThumb id="chess-camp-vol-3" direction="DESC"/>
              <ChannelThumb id="chess-camp-vol-4" direction="DESC"/>
            </OneThird>
          </Products>
        </Content>
      </Container>
    </ApolloProvider>
  );
};

export default Main;
