import ApolloClient from "apollo-boost";
import { InMemoryCache } from "apollo-cache-inmemory";

import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });

const client = new ApolloClient({
  cache,
  uri: "https://api.are.na/graphql",
  headers: {
    "X-APP-TOKEN": "f0e391533bdc71ab64a3",
  },
});

export default client;
